<template>
	<SectionHeader :image="null" title="Verein" page="Spenden an den Verein" />
	<div class="container">
		<h5>Wie kann ich an den Verein spenden?</h5>
		<p>Wir als Brauchtumsverein nehmen spenden unter folgendem Bankkonto entgegen:</p>
		<dt>Kontoinhaber</dt>
		<dd>Landsmannschaft der Banater Schwaben KV RT</dd>
		<dt>IBAN</dt>
		<dd>DE77 6405 0000 0001 8827 46</dd>
		<dt>Verwendungszweck</dt>
		<dd>Spende für Brauchtumpflege</dd>
	</div>
</template>
<script>
import SectionHeader from '@/views/SectionHeader.vue'
export default {
	name: 'Spenden',
	components: {
		SectionHeader
	},
	mounted () {
		window.scrollTo(0, 0)
	}
}
</script>
